import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import PostItem from '../components/PostItem';
import TitlePage from '../components/TitlePage';
import useTranslations from '../components/useTranslations';

import * as S from '../components/ListWrapper/styled';

const Products = ({ data: { allMarkdownRemark } }) => {
  // useTranslations is aware of the global context (and therefore also "locale")
  // so it'll automatically give back the right translations
  const {
    productspage,
  } = useTranslations();

  const postList = allMarkdownRemark.edges;

  return (
    <div className="productspage">
      <SEO title="productspage" />
      <TitlePage text={productspage} />
      <hr style={{ margin: `2rem 0` }} />
      <br />

      <S.ListWrapper>
        {postList.map(
          ({
            node: {
              frontmatter: {
                background,
                category,
                date,
                description,
                title,
                image,
              },
              timeToRead,
              fields: { slug },
            },
          }) => {
              if( category==="Product") {
                  return <PostItem
                    slug={`/projects/${slug}`}
                    background={background}
                    category={category}
                    date={date}
                    timeToRead={timeToRead}
                    title={title}
                    description={description}
                    image={image}
                />
                } else {
                    return null;
                }
          },
        )}
      </S.ListWrapper>

      <br />
    </div>
  );
};

export default Products;

export const query = graphql`
  query Products($locale: String!, $dateFormat: String!, ) {
    allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } },
        fileAbsolutePath: {regex: "/(projects)\/.*\\.md$/"},
        frontmatter: {category: {ne: "Project"}}
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            category
            background
            image
            date(formatString: $dateFormat)

          }
          timeToRead
          fields {
            locale
            slug
          }
        }
      }
    }
  }
`;
